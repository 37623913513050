import {
  Dialog,
  Bar,
  BarDesign
} from '@ui5/webcomponents-react';

import Button from './Button';
import Terms from 'features/terms';
import Privacy from 'features/privacy';

import { MutableRefObject } from 'react';
import styled from 'styled-components';

import { useLanguages } from 'utils/hooks';

type Props = {
  dialogRef: MutableRefObject<any>;
  type: string;
  url?: string;
};

const ContentIFrame = styled.iframe`
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  height: 89vh;
  border: none;
  overflow: hidden;
`;

function ContentDialog({ dialogRef, type, url }: Props) {
  const { messages: lang } = useLanguages();

  return (
    <Dialog
      stretch
      ref={dialogRef}
      footer={
        <Bar
          design={BarDesign.Footer}
          endContent={
            <Button
              type='Primary'
              text={lang['close']}
              slot='endContent'
              onClick={() => dialogRef.current.close()}
            />
          }
        />
      }
    >
     {type ==='eula' && <ContentIFrame src={url} scrolling='yes' />}
     {type ==='terms' && <Terms />}
     {type ==='privacy' && <Privacy />}
    </Dialog>
  );
}

export default ContentDialog;