import fetch from 'isomorphic-fetch';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import useEndpoints from './endpoints';
import { APIS, UPDATE_APIS, DELETE_APIS } from './helpers';
import { apiState, apiStatus } from '../atoms';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { loginRequest } from 'components/UI5/SSO/authConfig';
// import GetBearerToken from 'components/UI5/SSO/ssoHelper';

// const _url = 'https://sap-dev.ineosautomotive.com';
const _url = process.env.REACT_APP_AGENT_PORTAL_API_URL + 'v1.0'; // icc URL
const _leadurl = process.env.REACT_APP_AGENT_PORTAL_API_URL + 'lead/v1.0'; // icc URL
const _leadMgturl =
  process.env.REACT_APP_AGENT_PORTAL_API_URL + 'saleslead/v1.0'; // icc URL
const _caseurl = process.env.REACT_APP_AGENT_PORTAL_API_URL + 'case/v1.0'; // icc URL

const _status: Record<string, string> = {};

let data = {};

export const useApi = () => {
  // const [data, setData] = useRecoilState<any>(apiState);
  const [status, setStatus] = useRecoilState<any>(apiStatus);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<any[]>();
  const { instance, accounts } = useMsal();
  // const getBearerToken = GetBearerToken(instance, accounts);
  const getBearerToken = async () => {
    var token = '';
    let response = await instance.acquireTokenSilent({
      ...(loginRequest as any),
      account: accounts[0] as any,
    });
    // .then((response) => {
    //   console.log('Response after login');
    //   console.log(response);
    //   token = response.accessToken;
    //   // callMsGraph(response.accessToken).then(response => setGraphData(response));
    // });
    token = response.accessToken;
    console.log('Below is the access token');
    console.log(token);
    return token;
    // const fName = localStorage.getItem('SSOBearerToken');

    // if (fName) document.cookie = 'SSOBearerToken=' + fName;

    // return fName || null;
  };

  const fetchApi = async (
    api: keyof APIS,
    path: string,
    options: any = { method: 'GET' },
    args?: { noCache?: boolean; asText?: boolean; cacheKey?: string }
  ) => {
    const key = `${api}:${
      typeof args === 'string' ? args : args ? JSON.stringify(args) : ''
    }`;
    if (api === 'userDealersFetch') console.log(key);
    try {
      if (api.indexOf('notification') === -1) {
        _status[key] = 'loading';
        setStatus({ ...status, [key]: 'loading' });
      }
      const response = await fetch(`${_url}${path}`, {
        ...options,
        headers: {
          Accept: 'application/json',
          'subscription-key': process.env.REACT_APP_AGENT_PORTAL_API_SUB_KEY,
          Authorization: `Bearer ${await getBearerToken()}`,
        },
      });
      if (response.status === 401) {
        // TODO: unauthed logic - move auth to recoil state
      }
      if (args?.asText) {
        _status[key] = 'done';
        setStatus({ ...status, [key]: 'done' });
        return response.text();
      }
      const json = await response.json();
      if (!args?.noCache) {
        const d = args?.cacheKey
          ? json[args?.cacheKey]
          : json?.d?.results || json?.d;
        console.log('cache :::', key, args?.cacheKey, d);
        data[key] = d;
      }
      // setData({ ...data, [key]: json?.d?.results || json?.d });
      if (api.indexOf('notification') === -1) {
        _status[key] = 'done';
        setStatus({ ...status, [key]: 'done' });
      }
      // setStatus(status + 1);
      // console.log({ api, status });
      if (args?.cacheKey) return json[args?.cacheKey];
      return json?.d?.results || json?.d || json?.value || json?.error;
    } catch (e: any) {
      console.error(`API:${key}:::::`, e);
      _status[key] = `error::${e.toString()}`;
      setStatus({ ...status, [key]: `error::${e.toString()}` });
    }
  };

  const fetchNoCache = async (
    api: keyof APIS,
    path: string,
    options: any = { method: 'GET' },
    args?: { noCache?: boolean; asText?: boolean; cacheKey?: string }
  ) => {
    const key = `${api}:${
      typeof args === 'string' ? args : args ? JSON.stringify(args) : ''
    }`;
    if (api === 'userDealersFetch') console.log(key);
    try {
      if (api.indexOf('notification') === -1) {
        _status[key] = 'loading';
      }
      const response = await fetch(`${_url}${path}`, {
        ...options,
        headers: {
          Accept: 'application/json',
          'subscription-key': process.env.REACT_APP_AGENT_PORTAL_API_SUB_KEY,
          Authorization: `Bearer ${await getBearerToken()}`,
        },
      });
      if (response.status === 401) {
        // TODO: unauthed logic - move auth to recoil state
      }
      if (args?.asText) {
        _status[key] = 'done';
        return response.text();
      }
      const json = await response.json();
      // setStatus(status + 1);
      // console.log({ api, status });
      if (args?.cacheKey) return json[args?.cacheKey];
      return json?.d?.results || json?.d || json?.value || json?.error;
    } catch (e: any) {
      console.error(`API:${key}:::::`, e);
      _status[key] = `error::${e.toString()}`;
    }
  };

  const updateApi = async (
    api: keyof UPDATE_APIS,
    path: string,
    token: string,
    options: any = { method: 'PATCH' }
  ) => {
    try {
      if (api.indexOf('notification') === -1)
        setStatus({ ...status, [api]: 'loading' });
      const response = await fetch(`${_url}${path}`, {
        ...options,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          // 'x-csrf-token': token,
          'subscription-key': process.env.REACT_APP_AGENT_PORTAL_API_SUB_KEY,
          Authorization: `Bearer ${await getBearerToken()}`,
        },
      });
      if (response.status === 401) {
        // TODO: unauthed logic - move auth to recoil state
      }

      if (response.status === 400) {
        return await response.json();
      }
      if (api.indexOf('notification') === -1)
        setStatus({ ...status, [api]: 'done' });
      if (api.includes('create')) {
        const json = await response.json();
        return json?.d?.results || json?.d || json?.value;
      }
      if (api === 'notificationAction') {
        const json = await response.json();
        return json;
      } else {
        return response;
      }
    } catch (e: any) {
      console.log(`API:${api}:::::`, e);
      setStatus({ ...status, [api]: `error::${e.toString()}` });
    }
  };

  const deleteApi = async (
    api: keyof DELETE_APIS,
    path: string,
    token: string,
    options: any = { method: 'DELETE' }
  ) => {
    try {
      setStatus({ ...status, [api]: 'loading' });
      const response = await fetch(`${_url}${path}`, {
        ...options,
        headers: {
          // 'x-csrf-token': token,
          'subscription-key': process.env.REACT_APP_AGENT_PORTAL_API_SUB_KEY,
          Authorization: `Bearer ${await getBearerToken()}`,
        },
      });
      if (response.status === 401) {
        // TODO: unauthed logic - move auth to recoil state
      }

      setStatus({ ...status, [api]: 'done' });
      return response;
    } catch (e: any) {
      console.error(`API:${api}:::::`, e);
      setStatus({ ...status, [api]: `error::${e.toString()}` });
    }
  };

  const fetchLeadApi = async (
    api: keyof APIS,
    path: string,
    options: any = { method: 'GET' },
    args?: { noCache?: boolean; asText?: boolean }
  ) => {
    const key = `${api}:${
      typeof args === 'string' ? args : args ? JSON.stringify(args) : ''
    }`;
    try {
      if (api.indexOf('notification') === -1) {
        _status[key] = 'loading';
        setStatus({ ...status, [key]: 'loading' });
      }
      const response = await fetch(`${_leadurl}${path}`, {
        ...options,
        headers: {
          Accept: 'application/json',
          'subscription-key': process.env.REACT_APP_AGENT_PORTAL_API_SUB_KEY,
          Authorization: `Bearer ${await getBearerToken()}`,
        },
      });
      if (response.status === 401) {
        // TODO: unauthed logic - move auth to recoil state
      }
      if (args?.asText) {
        _status[key] = 'done';
        setStatus({ ...status, [key]: 'done' });
        return response.text();
      }
      const json = await response.json();
      // console.log('MAP :::', json);
      if (!args?.noCache) data[key] = json?.d?.results || json?.d || json;
      // setData({ ...data, [key]: json?.d?.results || json?.d });
      if (api.indexOf('notification') === -1) {
        _status[key] = 'done';
        setStatus({ ...status, [key]: 'done' });
      }
      // setStatus(status + 1);
      // console.log({ api, status });
      return json?.d?.results || json?.d || json?.value || json?.error || json;
    } catch (e: any) {
      console.error(`API:${key}:::::`, e);
      _status[key] = `error::${e.toString()}`;
      setStatus({ ...status, [key]: `error::${e.toString()}` });
    }
  };

  const fetchLeadMgtApi = async (
    api: keyof APIS,
    path: string,
    options: any = { method: 'GET' },
    args?: { noCache?: boolean; asText?: boolean }
  ) => {
    const key = `${api}:${
      typeof args === 'string' ? args : args ? JSON.stringify(args) : ''
    }`;
    // console.log('fetch key ::::', key);
    try {
      if (api.indexOf('notification') === -1) {
        _status[key] = 'loading';
        setStatus({ ...status, [key]: 'loading' });
      }
      const response = await fetch(`${_leadMgturl}${path}`, {
        ...options,
        headers: {
          Accept: 'application/json',
          'subscription-key': process.env.REACT_APP_AGENT_PORTAL_API_SUB_KEY,
          Authorization: `Bearer ${await getBearerToken()}`,
        },
      });
      if (response.status === 401) {
        // TODO: unauthed logic - move auth to recoil state
      }
      if (args?.asText) {
        _status[key] = 'done';
        setStatus({ ...status, [key]: 'done' });
        return response.text();
      }
      const json = await response.json();
      if (!args?.noCache) data[key] = json?.leads || json?.d;
      if (api.indexOf('notification') === -1) {
        _status[key] = 'done';
        setStatus({ ...status, [key]: 'done' });
      }
      // setStatus(status + 1);
      // console.log({ api, status });
      return json?.leads || json?.d || json?.value || json?.error;
    } catch (e: any) {
      console.error(`API:${key}:::::`, e);
      _status[key] = `error::${e.toString()}`;
      setStatus({ ...status, [key]: `error::${e.toString()}` });
    }
  };

  const fetchCaseApi = async (
    api: keyof APIS,
    path?: string,
    options: any = { method: 'GET' },
    args?: { noCache?: boolean; asText?: boolean; cacheKey?: string }
  ) => {
    const key = `${api}:${
      typeof args === 'string' ? args : args ? JSON.stringify(args) : ''
    }`;
    try {
      if (api.indexOf('notification') === -1) {
        _status[key] = 'loading';
        setStatus({ ...status, [key]: 'loading' });
      }
      const response = await fetch(`${_caseurl}${path}`, {
        ...options,
        headers: {
          Accept: 'application/json',
          'subscription-key': process.env.REACT_APP_AGENT_PORTAL_API_SUB_KEY,
          Authorization: `Bearer ${await getBearerToken()}`,
        },
      });
      if (response.status === 401) {
        // TODO: unauthed logic - move auth to recoil state
      }
      if (args?.asText) {
        _status[key] = 'done';
        setStatus({ ...status, [key]: 'done' });
        return response.text();
      }
      const json = await response.json();
      if (!args?.noCache) {
        const d = args?.cacheKey
          ? json[args?.cacheKey]
          : json?.d?.results || json?.d;
        console.log('cache case :::', key, args?.cacheKey, d);
        data[key] = d;
      }
      // !args?.noCache &&
      //   setData({ ...data, [key]: json?.d?.results || json?.d });
      if (api.indexOf('notification') === -1) {
        _status[key] = 'done';
        setStatus({ ...status, [key]: 'done' });
      }
      // setStatus(status + 1);
      // console.log({ api, status });
      if (args?.cacheKey) return json[args?.cacheKey];
      return json;
    } catch (e: any) {
      console.error(`API:${key}:::::`, e);
      _status[key] = `error::${e.toString()}`;
      setStatus({ ...status, [key]: `error::${e.toString()}` });
    }
  };

  const { _apis, _updateAPIs, _deleteAPIs } = useEndpoints({
    fetchApi,
    updateApi,
    deleteApi,
    fetchLeadApi,
    fetchLeadMgtApi,
    fetchCaseApi,
    fetchNoCache,
  });

  useEffect(() => {
    const f = () => {
      const r1 = Object.values(_status).some((x) => x === 'loading');
      setIsLoading(r1);
      const r2 = Object.values(_status).filter((x: any) =>
        x?.includes('error::')
      );
      setIsError(r2);
    };
    setTimeout(f, 10);
  }, [status]);

  const callUpdate = async (api: keyof UPDATE_APIS, data: any) => {
    const response = await _updateAPIs[api](data);
    return response;
  };

  const callDelete = async (
    api: keyof DELETE_APIS,
    token: any,
    service: string,
    entity: string
  ) => {
    const response = await _deleteAPIs[api](service, entity);
    return response;
  };

  const call = async (api: keyof APIS, args?: any) => {
    const key = `${api}:${
      typeof args === 'string' ? args : args ? JSON.stringify(args) : ''
    }`;
    if (api === 'userDealersFetch') console.log(key);
    if (_status[key] === 'loading') return [];
    const response = await _apis[api](args);
    return response;
  };

  const get = async (api: keyof APIS, args?: any): Promise<any> => {
    const key = `${api}:${
      typeof args === 'string' ? args : args ? JSON.stringify(args) : ''
    }`;
    console.log('get ::::', key, data?.[key]);
    if (!!data?.[key]) return data?.[key];
    if (_status[key] === 'loading') return [];
    const response = await call(api, args);
    return response;
  };

  const cached = async (api: keyof APIS, args?: any): Promise<any> => {
    const key = `${api}:${
      typeof args === 'string' ? args : args ? JSON.stringify(args) : ''
    }`;
    // console.log('cache key ::::', key);
    if (data?.[key]) return data?.[key];
    return undefined;
  };

  return {
    call,
    get,
    cached,
    fetchApi,
    fetchLeadMgtApi,
    deleteApi,
    updateApi,
    callDelete,
    callUpdate,
    _status,
    isLoading,
    isError,
    getBearerToken,
  };
};
