import { atom } from 'recoil';

export const authState = atom({
  key: 'auth',
  default: {
    user: undefined,
    status: 'loading',
  },
  dangerouslyAllowMutability: true,
});

export const rootState = atom({
  key: 'root',
  default: {},
});

export const apiState = atom({
  key: 'apiData',
  default: {},
});

export const apiStatus = atom({
  key: 'apiStatus',
  default: 0,
});

export const myTickets = atom({
  key: 'myTickets',
  default: {},
});

export const ticketCodes = atom({
  key: 'ticketCodes',
  default: {},
});
