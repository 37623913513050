import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';

import { LanguageContext } from 'components/Language/Context';
import LanguageSelector from 'components/Language/Selector';
import { useApi } from 'utils/api';

const Foot = styled.footer<{ $data: any; $fixed: boolean }>`
  ${(props) => {
    return !!props.$fixed
      ? 'position: fixed; bottom: 0; left: 0; right: 0;'
      : '';
  }}
  width: 100%;
  background-color: ${(props) => props.$data?.backgroundcolour};
  background-color: #00142c;
  background-image: url(https://ineos-prd-cdn.azureedge.net/-/media/themes/ial/ialshared/ialshared/images/header-bg.jpg);
  background-position: -60px 0;
  background-repeat: no-repeat;
  background-size: auto 100%;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  li {
    float: left;
  }

  li a {
    display: block;
    color: #ccc;
    text-align: center;
    font-size: 14px;
    padding: 8px;
    text-decoration: none;
    transition: color linear 100ms;
    &:hover {
      color: red;
    }
  }

  /* @media (max-width: 600px) {
    position: relative;
  } */
`;

function Footer() {
  const { messages } = useContext(LanguageContext);
  const loc = useLocation();
  const { _status } = useApi();

  const resized = useCallback(() => {
    const h = document.getElementById('x-header');
    const h2 = document.getElementById('vss-shell-bar');
    const c = document.getElementById('x-content');
    const r = document.getElementById('root');
    const f = document.getElementById('x-footer');
    if (c)
      c.style.height = `${
        window.innerHeight -
        (f?.clientHeight || 0) -
        (h?.clientHeight || 0) -
        (h2?.clientHeight || 0) -
        1
      }px`;
    if (r) r.style.height = window.innerHeight + 'px';
    if (!f) setTimeout(resized, 100);
  }, []);

  const dbResize = useMemo(
    () =>
      debounce(() => {
        resized();
      }, 500),
    [resized]
  );

  if (Object.values(_status).filter((x) => x === 'loading').length === 0) {
    dbResize();
  }

  useEffect(() => {
    window.addEventListener('resize', resized);

    setTimeout(resized, 500);
    return () => {
      window.removeEventListener('resize', resized);
    };
  }, [loc, resized]);

  useEffect(() => {
    const f = () => {
      const ifrs = window.document.getElementsByTagName('iframe');
      const arr = Array.from(ifrs);
      arr?.forEach((ifr) => (ifr.style.position = 'relative'));
    };
    const int = setInterval(f, 1000);
    return () => clearInterval(int);
  }, []);

  return (
    <Foot
      id="x-footer"
      className="w-full"
      $fixed
      $data={{ backgroundcolour: '#00142c' }}
    >
      <div className="flex flex-initial justify-around">
        <ul>
          <li
            style={{ height: '100%', position: 'relative', top: '-1.5px' }}
            className="flex items-center pl-4"
          >
            <span style={{ color: 'white' }} className="fas fa-globe" />{' '}
            <LanguageSelector color="white" />
          </li>
        </ul>
        <div style={{ padding: '16px', color: 'white' }}>© INEOS</div>
      </div>
    </Foot>
  );
}

export default Footer;
