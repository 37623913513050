import {
  ResponsivePopover,
  PopoverHorizontalAlign,
  PopoverPlacementType,
  FlexBox,
  Avatar,
  Text,
  List,
  StandardListItem,
  Button,
} from '@ui5/webcomponents-react';

import MyAccountDialog from './MyAccountDialog';

import '@ui5/webcomponents-icons/dist/AllIcons.js';

import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { CSSProperties } from 'styled-components';

import { useLanguages } from 'utils/hooks';
import { VSSUser } from 'types';
import { useApi } from 'utils/api';
import ContentDialog from './ContentDialog';
import { useMsal } from '@azure/msal-react';

type Props = {
  id?: string;
  style?: CSSProperties;
  popoverRef: MutableRefObject<any>;
  user: VSSUser;
};

const styles = {
  header: {
    padding: '1rem',
    borderBottom: '1px solid var(--ineos_secondaryUIGrey2)',
  },
  footerButton: {
    width: '100%',
    height: '44px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: 'var(--ineos_primaryRed)',
    '&:hover': {
      color: '#fff',
      backgroundColor: 'var(--ineos_primaryRed)',
    },
    '&:focus': {
      color: '#fff',
      backgroundColor: 'var(--ineos_primaryRed)',
    },
    '&:active': {
      color: '#fff',
      backgroundColor: 'var(--ineos_primaryRed)',
    },
  },
};

function ProfilePopover({ id, style, popoverRef, user }: Props) {
  const { instance } = useMsal();
  const { messages: lang } = useLanguages();
  const useStyles = createUseStyles(styles);
  const classes = useStyles();
  const history = useHistory();
  const contentDialogRef = useRef<any>(null);
  const [dialogContent, setDialogContent] = useState('');

  // useEffect(() => {

  // })

  const myAccountDialogRef = useRef<any>(null);

  const handleAfterOpen = (e) => {
    e.target.childNodes[1].childNodes[1].childNodes.forEach(
      (i) => (i.focused = false)
    );
    e.target.footer[0]?.shadowRoot?.children[0].setAttribute(
      'style',
      'justify-content: left; padding-left: 1rem;'
    );
  };

  const handleItemClick = (e) => {
    const sRoute = e.detail.item.getAttribute('data-route');
    const sAction = e.detail.item.getAttribute('data-action');
    if (sRoute) {
      history.push(e.detail.item.getAttribute('data-route'));
    } else if (sAction === 'myAccount') {
      myAccountDialogRef.current.show(true);
    }
    popoverRef.current.close();
  };

  const handleLogout = (e) => {
    instance
      .logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/',
      })
      .then((e) => {
        document.cookie = 'FULLNAME=' + '';
        localStorage.removeItem('fullName');
        localStorage.removeItem('xname');
      });
    // history.replace('/');
    // location.reload();
  };

  const handleContentDialog = (e) => {
    setDialogContent(e.currentTarget.getAttribute('data-type'));
    contentDialogRef.current.show();
  };

  return (
    <>
      <ResponsivePopover
        id={id}
        style={style}
        ref={popoverRef}
        hideArrow={false}
        horizontalAlign={PopoverHorizontalAlign.Right}
        placementType={PopoverPlacementType.Bottom}
        onAfterOpen={handleAfterOpen}
        footer={
          <Button
            className={classes.footerButton}
            icon="log"
            onClick={handleLogout}
          >
            {lang['logout']}
          </Button>
        }
      >
        <FlexBox direction="Column">
          <FlexBox className={classes.header}>
            <Avatar size="S" icon="employee" colorScheme="Accent10" />
            <div
              style={{
                paddingLeft: '0.5rem',
                display: 'grid',
                alignItems: 'center',
              }}
            >
              <Text>{user?.Name}</Text>
              {/* <Text>Role description here</Text> */}
            </div>
          </FlexBox>
          <List onItemClick={handleItemClick}>
            <StandardListItem data-action="myAccount">
              {lang['myAccount']}
            </StandardListItem>
            <StandardListItem onClick={handleContentDialog}>
              {lang['terms']}
            </StandardListItem>
            {/* <StandardListItem data-route="/privacy">
              {lang['EULA']}
            </StandardListItem> */}
          </List>
        </FlexBox>
      </ResponsivePopover>
      {createPortal(
        <MyAccountDialog dialogRef={myAccountDialogRef} user={user} />,
        document.body
      )}
      {createPortal(
        <ContentDialog dialogRef={contentDialogRef} url="" type={'terms'} />,
        document.body
      )}
    </>
  );
}

export default ProfilePopover;
